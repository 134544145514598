import React, { useState, useEffect } from "react";
import "./SelfOnboard.scss";
import { Paper } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../utils/config/url";

const PersonalDetails = ({ onBoardInfo, setActive, employeeId }) => {
  const [basicDetails, setBasicDetails] = useState({
    employeeDOB: "",
    personalMobile: "",
    personalEmail: "",
    gender: "",
  });
  const [address, setAddress] = useState({
    fullAddress: "",
    landMark: "",
    city: "",
    state: "",
    pinCode: "",
  });
  const [emergencyContact, setEmergencyContact] = useState({
    name: "",
    mobile: "",
    address: "",
  });
  const [salaryAccount, setSalaryAccount] = useState({
    accountNumber: "",
    accountHolderName: "",
    bankName: "",
    branchName: "",
    IFSCCode: "",
  });
  const [loading, setLoading] = useState(false);

  const dateFormatFromUTCToLocal = (input) => {
    let date = new Date(input).getDate();
    if (date < 10) {
      date = `0${date}`;
    }

    let month = new Date(input).getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }

    let year = new Date(input).getFullYear();
    if (year < 10) {
      year = `0${year}`;
    }

    return `${year}-${month}-${date}`;
  };
  useEffect(() => {
    let isMounted = true;

    const getEmployeeById = async () => {
      setLoading(false);
      try {
        const res = await axios.get(
          `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${onBoardInfo.token}`,
            },
          }
        );

        if (isMounted) {
          if (res.data.status === true) {
            setBasicDetails({
              employeeDOB: res.data.employee.employeeDOB,
              personalMobile: res.data.employee.personalMobile,
              personalEmail: res.data.employee.personalEmail,
              gender: res.data.employee.gender,
            });
            setAddress({
              fullAddress: res?.data?.employee?.employeeAddress?.fullAddress,
              landMark: res?.data?.employee?.employeeAddress?.landMark,
              city: res?.data?.employee?.employeeAddress?.city,
              state: res?.data?.employee?.employeeAddress?.state,
              pinCode: res?.data?.employee?.employeeAddress?.pinCode,
            });
            setEmergencyContact({
              name: res?.data?.employee?.emergencyContact?.name,
              mobile: res?.data?.employee?.emergencyContact?.mobile,
              address: res?.data?.employee?.emergencyContact?.address,
            });
            setSalaryAccount({
              accountNumber: res?.data?.employee?.salaryAccount?.accountNumber,
              accountHolderName:
                res?.data?.employee?.salaryAccount?.accountHolderName,
              bankName: res?.data?.employee?.salaryAccount?.bankName,
              branchName: res?.data?.employee?.salaryAccount?.branchName,
              IFSCCode: res?.data?.employee?.salaryAccount?.IFSCCode,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching employee details:", error);
        // Handle the error as needed
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    };

    getEmployeeById();

    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, [loading]);

  const handleBasicDetailChange = (e) => {
    const { name, value } = e.target;
    setBasicDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEmergencyContactChange = (e) => {
    const { name, value } = e.target;
    setEmergencyContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSalaryAccountChange = (e) => {
    const { name, value } = e.target;
    setSalaryAccount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePersonalDetailUpdate = (e) => {
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/api/v1/hrmgmt/employee/updateEmployee`,
        {
          employeeId: employeeId,
          update: {
            employeeDOB: basicDetails.employeeDOB,
            personalMobile: basicDetails.personalMobile,
            personalEmail: basicDetails.personalEmail,
            gender: basicDetails.gender,
            emergencyContact: emergencyContact,
            employeeAddress: address,
            salaryAccount: salaryAccount,
          },
        },
        {
          headers: { Authorization: `bearer ${onBoardInfo.token}` },
        }
      )
      .then((response) => {
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            setLoading(true);
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          text: err.response.data.error,
        });
      });
  };

  return (
    <div className="selfOnboard">
      <Paper elevation={3} className="selfOnboard__paper">
        <form onSubmit={handlePersonalDetailUpdate}>
          <div className="selfOnboard__paper__header">
            <h3>Basic Details</h3>
          </div>
          <div className="selfOnboard__paper__content">
            <div className="row justify-content-start">
              <div className="col-4">
                <label>Employee Full Name</label>
                <input
                  className="form-control"
                  value={onBoardInfo?.employee?.employeeFullName}
                  readOnly
                  type="text"
                />
              </div>
              <div className="col-4">
                <label>Employee Role</label>
                <input
                  className="form-control"
                  type="text"
                  readOnly
                  value={onBoardInfo?.employee?.employeeRole}
                />
              </div>
              <div className="col-4">
                <label>Date of Joining</label>
                <input
                  className="form-control"
                  type="date"
                  readOnly
                  value={dateFormatFromUTCToLocal(onBoardInfo?.onboarding?.joiningDate)}
                />
              </div>
            </div>
            <br />
            <div className="row justify-content-start">
              <div className="col-3">
                <label>Date of Birth *</label>
                <input
                  className="form-control"
                  type="date"
                  name="employeeDOB"
                  required
                  value={dateFormatFromUTCToLocal(basicDetails?.employeeDOB)}
                  onChange={handleBasicDetailChange}
                />
              </div>
              <div className="col-3">
                <label>Personal Phone Number *</label>
                <input
                  className="form-control"
                  type="number"
                  name="personalMobile"
                  required
                  value={basicDetails?.personalMobile}
                  onChange={handleBasicDetailChange}
                />
              </div>
              <div className="col-3">
                <label>Personal Email *</label>
                <input
                  className="form-control"
                  type="email"
                  name="personalEmail"
                  required
                  value={basicDetails?.personalEmail}
                  onChange={handleBasicDetailChange}
                />
              </div>
              <div className="col-3">
                <label>Gender*</label>
                <select
                  className="form-control"
                  required
                  value={basicDetails?.gender}
                  name="gender"
                  onChange={handleBasicDetailChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <br />
            <div className="selfOnboard__paper__header">
              <h3>Employee Address</h3>
            </div>
            <div className="row justify-content-start">
              <div className="col-12">
                <label>Full Address *</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="fullAddress"
                  value={address?.fullAddress}
                  onChange={handleAddressChange}
                />
              </div>
            </div>
            <br />
            <div className="row justify-content-start">
              <div className="col-3">
                <label>Land Mark *</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="landMark"
                  value={address?.landMark}
                  onChange={handleAddressChange}
                />
              </div>
              <div className="col-3">
                <label>City *</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="city"
                  value={address?.city}
                  onChange={handleAddressChange}
                />
              </div>
              <div className="col-3">
                <label>State *</label>
                <input
                  className="form-control"
                  type="text"
                  required
                  name="state"
                  value={address?.state}
                  onChange={handleAddressChange}
                />
              </div>
              <div className="col-3">
                <label>Pincode *</label>
                <input
                  className="form-control"
                  type="number"
                  required
                  name="pinCode"
                  value={address?.pinCode}
                  onChange={handleAddressChange}
                />
              </div>
            </div>
            <br />
            <div className="selfOnboard__paper__header">
              <h3>Emergency Contact</h3>
            </div>
            <div className="row justify-content-start">
              <div className="col-3">
                <label>Full Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={emergencyContact?.name}
                  onChange={handleEmergencyContactChange}
                />
              </div>
              <div className="col-3">
                <label>Phone Number</label>
                <input
                  className="form-control"
                  type="number"
                  name="mobile"
                  value={emergencyContact?.mobile}
                  onChange={handleEmergencyContactChange}
                />
              </div>
              <div className="col-5">
                <label>Full Address</label>
                <input
                  className="form-control"
                  type="text"
                  name="address"
                  value={emergencyContact?.address}
                  onChange={handleEmergencyContactChange}
                />
              </div>
            </div>
            <br />
            <div className="selfOnboard__paper__header">
              <h3>Salary Account Details</h3>
            </div>
            <div className="row justify-content-start">
              <div className="col-3">
                <label>Account Number</label>
                <input
                  className="form-control"
                  type="text"
                  name="accountNumber"
                  value={salaryAccount?.accountNumber}
                  onChange={handleSalaryAccountChange}
                />
              </div>
              <div className="col-3">
                <label>Account Holder Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="accountHolderName"
                  value={salaryAccount?.accountHolderName}
                  onChange={handleSalaryAccountChange}
                />
              </div>
              <div className="col-3">
                <label>Bank Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="bankName"
                  value={salaryAccount?.bankName}
                  onChange={handleSalaryAccountChange}
                />
              </div>
              <div className="col-3">
                <label>Branch Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="branchName"
                  value={salaryAccount?.branchName}
                  onChange={handleSalaryAccountChange}
                />
              </div>
            </div>
            <br />
            <div className="row justify-content-start">
              <div className="col-3">
                <label>IFSC Code</label>
                <input
                  className="form-control"
                  type="text"
                  name="IFSCCode"
                  value={salaryAccount?.IFSCCode}
                  onChange={handleSalaryAccountChange}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-3">
              <button type="submit">Update Personal Details</button>
            </div>
          </div>
        </form>
        <div className="row justify-content-end">
          <div className="col-1">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setActive("govtId");
              }}
            >
              Next
            </button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default PersonalDetails;
