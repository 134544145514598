import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { BASE_URL } from "../../../utils/config/url";
import Swal from "sweetalert2";
const SalaryStructure = ({ salaryAccount, employeeId }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { accountNumber, accountHolderName, bankName, branchName, IFSCCode } =
    salaryAccount;

  const [salaryStructure, setSalaryStructure] = useState({
    monthlyPersonalAllowance: "",
    yearlyPersonalAllowance: "",
    monthlyUniformNLaundryAllowance: "",
    yearlyUniformNLaundryAllowance: "",
    monthlyMealAllowance: "",
    yearlyMealAllowance: "",
    monthlyTravelAllowance: "",
    yearlyTravelAllowance: "",
    monthlyPhoneAndDataAllowance: "",
    yearlyPhoneAndDataAllowance: "",
    monthlyProvidentFund: "",
    yearlyProvidentFund: "",
    monthlyGratuity: "",
    yearlyGratuity: "",
    medicalPremium: "",
    monthlyPerfBonus: "",
    yearlyPerfBonus: "",
    monthlyTotal: "",
    annualCTC: "",
    monthlyPerformanceBonus: "",
    yearlyPerformanceBonus: "",
    ctcDocumentLink: "",
    yearlyFestivalBonus: "",
    eligibleForPF: "",
  });

  useEffect(() => {
    const controller = new AbortController();

    try {
      const getSalaryDetails = async (employeeId) => {
        const data = await axios.get(
          `${BASE_URL}/api/v1/hrmgmt/salarystructure/getSalaryStructureByEmployeeId/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo?.token}`,
            },
          }
        );

        setSalaryStructure(data?.data?.salaryStructure);
      };
      getSalaryDetails(employeeId);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.message,
        showConfirmButton: true,
      });
    }

    return () => {
      controller.abort();
    };
  }, []);
  if (!employeeId) {
    return;
  }
  const RedSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#EE4B46",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#EE4B46",
    },
  }));
  return (
    <div>
      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <h4 className="text-center mb-4">
          <b>Salary Account Details</b>
        </h4>

        <div className="row justify-content-start">
          <div className="col-4">
            <label>Account No.</label>
            <input
              type="text"
              value={accountNumber}
              disabled
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Account Holder Name</label>
            <input
              type="text"
              value={accountHolderName}
              disabled
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>Bank Name</label>
            <input
              type="text"
              value={bankName}
              disabled
              className="form-control"
            />
          </div>
        </div>

        <div className="row justify-content-start mt-3">
          <div className="col-4">
            <label>Branch Name</label>
            <input
              type="text"
              value={branchName}
              disabled
              className="form-control"
            />
          </div>

          <div className="col-4">
            <label>IFSC Code</label>
            <input
              type="text"
              value={IFSCCode}
              disabled
              className="form-control"
            />
          </div>

          <div className="col-4 text-center">
            {/* <button
              className="view-salary-structure__btn mt-4"
              style={{ fontSize: "15px" }}
             
            >
              Update Salary Account
            </button>
            */}
          </div>
        </div>
      </Paper>

      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="row justify-content-center view-salary-structure__headers">
          <div className="col-6">Component</div>
          <div className="col-3">Monthly</div>
          <div className="col-3">Yearly</div>
        </div>

        <br />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Basic & HRA</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Basic Salary</h5>
            <h5>HRA</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              readOnly
              className="form-control"
              value={salaryStructure?.monthlyBasic}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.monthlyHRA}
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyBasic}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyHRA}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Allowances</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Personal Allowance</h5>
            <h5>Uniform and Laundry Allowance</h5>
            <h5>Meal Allowance</h5>
            <h5>Travel Allowance</h5>
            <h5>Phone & Date Allowance</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.monthlyPersonalAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.monthlyUniformNLaundryAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.monthlyMealAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.monthlyTravelAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.monthlyPhoneAndDataAllowance}
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyPersonalAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyUniformNLaundryAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyMealAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyTravelAllowance}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyPhoneAndDataAllowance}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Retirals & Insurance</em>
            <FormGroup
              style={{
                display: "flex",
                justifyContent: "center",
                width: "200px",
                margin: "auto",
              }}
            >
              <FormControlLabel
                control={<RedSwitch checked={salaryStructure?.eligibleForPF} />}
                label="PF"
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            {salaryStructure?.eligibleForPF && (
              <>
                <h5>Provident Fund</h5>
                <h5>Gratuity</h5>
              </>
            )}
            <h5>Medical Insurance Premium</h5>
          </div>

          <div className="col-3">
            {salaryStructure?.eligibleForPF && (
              <>
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={salaryStructure?.monthlyProvidentFund}
                />
                <br />
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={salaryStructure?.monthlyGratuity}
                />
              </>
            )}
          </div>

          <div className="col-3">
            {salaryStructure?.eligibleForPF && (
              <>
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={salaryStructure?.yearlyProvidentFund}
                />
                <br />
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={salaryStructure?.yearlyGratuity}
                />
                <br />
              </>
            )}

            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.medicalPremium}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Bonus</em>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-center view-salary-structure__components">
            <h5>Performance Bonus</h5>
            <h5>Yearly Festival Bonus</h5>
          </div>

          <div className="col-3">
            <input
              type="number"
              readOnly
              className="form-control"
              value={salaryStructure?.monthlyPerformanceBonus}
            />
          </div>

          <div className="col-3">
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyPerformanceBonus}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.yearlyFestivalBonus}
            />
          </div>
        </div>

        <hr />

        <div className="row view-salary-structure__titles">
          <div className="col-6 text-center">
            <em>Total</em>
          </div>
        </div>

        <div className="row">
          <div className="col-4 text-center view-salary-structure__components">
            <h5>Monthly Total</h5>
            <h5>Annual CTC</h5>
          </div>

          <div className="col-4">
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.monthlyTotal}
            />
            <br />
            <input
              type="number"
              className="form-control"
              readOnly
              value={salaryStructure?.annualCTC}
            />
          </div>
        </div>

        <div className="row justify-content-center">
          <a
            href={salaryStructure?.ctcDocumentLink}
            download="Salary Structure"
            target="_blank"
            className="col-4 text-center mt-5"
            rel="noreferrer"
          >
            <button
              className="view-salary-structure__btn mt-3"
              style={{ fontSize: "15px" }}
              disabled={!salaryStructure?.ctcDocumentLink}
            >
              Download
            </button>
          </a>

          {/* <div className="col-4 text-center mt-5 ml-3">
            <button
              className="view-salary-structure__btn mt-3"
              style={{ fontSize: "15px" }}
              // disabled={!ctcDownload}
              // onClick={() => {
              //   setDeleteModal(true);
              // }}
            >
              Delete CTC
            </button>
          </div> */}
        </div>
      </Paper>
    </div>
  );
};

export default SalaryStructure;
