import React from "react";
import "./ViewWorkHistory.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/config/url";
import { useParams } from "react-router-dom";
import AddWorkHistory from "./AddWorkHistory/AddWorkHistory";
import ShowWorkHistory from "./ShowWorkHistory/ShowWorkHistory";
import Swal from "sweetalert2";

const ViewWorkHistory = ({ employeeId: propEmployeeId, onBoardInfo }) => {
  const [work, setWork] = useState([]);
  const { employeeId: paramEmployeeId } = useParams();
  const [loading, setLoading] = useState(false);
  const employeeId = propEmployeeId ? propEmployeeId : paramEmployeeId;


  let userInfo;
  if (onBoardInfo) {
    userInfo = onBoardInfo;
  } else {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  }
  useEffect(() => {
    let isMounted = true;

    const getEmployeeworkDetails = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/api/v1/hrmgmt/employee/getEmployeeByEmployeeId/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );

        if (isMounted) {
          setWork(res.data.employee.previousEmployerDetails);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error,
          showConfirmButton: true,
        });
        // Handle the error as needed
      }
    };

    getEmployeeworkDetails();

    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, [loading]);


  return (
    <div className="work">
      <br />
      <AddWorkHistory setLoading={setLoading} userInfo={userInfo} employeeId={employeeId} />
      <br />

      {work.length !== 0 ? (
        work.map((work, index) => {
          return (
            <ShowWorkHistory
              key={index}
              work={work}
              employeeId={employeeId}
              setLoading={setLoading}
              userInfo={userInfo}
            />
          );
        })
      ) : (
        <p>No work details found</p>
      )}
    </div>
  );
};

export default ViewWorkHistory;
