import React from "react";
import { Paper } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/config/url";
import { useParams } from "react-router-dom";

const OnBoardingFormalities = ({ onboardingStatus, loading, setLoading }) => {
  const { onboardingId } = useParams();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const handleSendOffer = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/hrmgmt/onboarding/sendOfferLetter`,
        {
          onboardingId: onboardingId,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success",
        text: response.data.message,
        showConfirmButton: true,
      }).then(() => {
        setLoading(!loading);
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.response.data.error,
        showConfirmButton: true,
      });
    }
  };
  return (
    <div>
      <Paper elevation={2} style={{ padding: "20px", marginTop: "40px" }}>
        <div className="row justify-content-end mr-3">
          <button
            className="sendoffer__btn"
            type="button"
            onClick={handleSendOffer}
            disabled={onboardingStatus !== "Initiated"}
            style={{
              backgroundColor: onboardingStatus !== "Initiated" ? "grey" : "",
            }}
          >
            Send Offer
          </button>
        </div>
      </Paper>
    </div>
  );
};

export default OnBoardingFormalities;
